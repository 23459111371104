<template>
  <div class="px-5 pt-5 pb-10 flex flex-col justify-between h-full overflow-scroll">
    <div>
      <router-link :to="{ name: 'StepTwo' }">
       <BackIcon class="mb-10" />
      </router-link>
      <h3 class=" text-center text-lg mb-10 px-14">Why did you download
        this (name)?</h3>

      <div class="flex flex-col">
        <label class="inline-flex mt-3">
          <div><input v-model="checkedOptions" type="checkbox" name="why" class="form-checkbox text-customOrange h-5 w-5" value="i_want_to_feel_less_stiff_when_first_wake_up"></div><span
            class="ml-2 text-gray-700 text-sm">I want to feel less stiff when I
            first wake up</span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="checkedOptions" type="checkbox" name="why" class="form-checkbox text-customOrange h-5 w-5" value="i_want_to_add_more_mobility_and_core_strength_to_my_current_routine">
          </div><span class="ml-2 text-gray-700 text-sm">I want to add more
            mobility and core strength to my current routine</span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="checkedOptions" type="checkbox" name="why" class="form-checkbox text-customOrange h-5 w-5" value="looking_for_a_way_to_break_away_from_work_and_de_stress_through_movement"></div><span
            class="ml-2 text-gray-700 text-sm">Looking for a way to break away
            from work and de-stress through movement </span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="checkedOptions" type="checkbox" name="why" class="form-checkbox text-customOrange h-5 w-5" value="i_sit_to_much_and_i_need_to_move_more">
          </div><span class="ml-2 text-gray-700 text-sm">I sit too much and I
            need to move more</span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="checkedOptions" type="checkbox" name="why" class="form-checkbox text-customOrange h-5 w-5" value="i_want_a_safe_low_impact_way_of_working_out"></div><span
            class="ml-2 text-gray-700 text-sm">I want a safe low impact way of
            working out</span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="checkedOptions" type="checkbox" name="why" class="form-checkbox text-customOrange h-5 w-5" value="a_friend_told_me_this_awesome_so_i_downloaded_it"></div><span
            class="ml-2 text-gray-700 text-sm">A friend told me this was awesome so
            I downloaded it</span>
        </label>

      </div>
    </div>
    <div>
      <div class="flex space-x-1 items-center justify-center mb-8">
        <div v-for="item in items" :key="item.id"
          class=" rounded-full h-2 w-2 bg-customPurple"></div>
      </div>
      <router-link class="w-10/12" :to="{ name: 'StepFour' }">
        <MainButton text="Next" class="h-14" />
      </router-link>
    </div>
  </div>
</template>

<script>
import BackIcon from "@/components/svg/BackIcon.vue";
import MainButton from "@/components/shared/MainButton.vue";
import { mapMutations } from 'vuex';

export default {
  name: "StepThree",
  components: {
    BackIcon,
    MainButton,
  },
  data: () =>  ({
    items: [
      "Item1", "Item2", "Item3", "Item4", "Item5", "Item6"
    ],
    checkedOptions: [],
  }),

  methods: {
    ...mapMutations({
      setWhy: 'onboarding/SET_WHY'
    }),
  },

  watch: {
    checkedOptions: function(value) {
      this.setWhy(value);
    }
  }
};
</script>
<style >
</style>